<template>
  <div class="main-page">
    <template v-if="showHeader">
      <section class="page-section mb-3">
        <div class="container-fluid">
          <div class="grid justify-content-between">
            <div class="col-12 md:col-12">
              <div class="">
                <div class="flex align-items-center">
                  <div>
                    <Avatar
                      size="large"
                      class="primary"
                      icon="pi pi-building"
                    />
                  </div>
                  <div class="ml-2">
                    <div class="text-2xl text-primary font-bold">
                      Institution Details
                    </div>
                    <div class="text-sm text-500">
                      Details of institution record
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-4">
                <Breadcrumb
                  :home="{
                    icon: 'pi pi-arrow-left text-lg mr-3',
                    label: 'Back',
                    command: () => {
                      $router.go(-1);
                    },
                  }"
                  :model="[
                    {
                      label: 'Caps Institutions List',
                      to: '/caps_institutions_list',
                    },
                    {
                      label: 'Institution Details',
                      to: `/caps_institutions_list/view/${id}`,
                    },
                  ]"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <hr />
    </template>
    <section class="page-section">
      <div class="container-fluid">
        <div class="grid">
          <div class="col comp-grid">
            <div class="card nice-shadow-18">
              <div>
                <div class="relative-position" style="min-height: 100px">
                  <template v-if="!loading && item">
                    <div class="row q-col-gutter-x-md">
                      <div class="col">
                        <div class="grid align-items-center">
                          <div class="col text-2xl font-bold">
                            {{ item.institution_name }}
                          </div>
                        </div>
                        <hr />
                      </div>
                    </div>
                  </template>
                  <template v-if="loading">
                    <div>
                      <table class="p-datatable-table w-full">
                        <tbody>
                          <tr v-for="n in 5" :key="n">
                            <td class="text-left" style="width: 30%">
                              <Skeleton height="2rem" class="m-2" />
                            </td>
                            <td class="text-left" style="width: 70%">
                              <Skeleton height="2rem" class="m-2" />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="p-3 text-center">
                      <ProgressSpinner style="width: 50px; height: 50px" />
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
//[--PAGE-IMPORT-STATEMENT--]
import { defineAsyncComponent, ref } from "vue";
import { PageMixin } from "../../mixins/page.js";
import { ViewPageMixin } from "../../mixins/viewpage.js";
import { mapActions, mapGetters, mapState } from "vuex";
export default {
  name: "viewCapsinstitutionslistPage",
  components: {
    //[--PAGE-COMPONENT-NAME--]
  },
  mixins: [PageMixin, ViewPageMixin],
  props: {
    pageName: {
      type: String,
      default: "caps_institutions_list",
    },
    idName: {
      type: String,
      default: "caps_institution_id",
    },
    routeName: {
      type: String,
      default: "caps_institutions_listview",
    },
    pagePath: {
      type: String,
      default: "caps_institutions_list/view",
    },
    apiPath: {
      type: String,
      default: "caps_institutions_list/view",
    },
  },
  data() {
    return {
      item: {
        default: {},
      },
    };
  },
  computed: {
    pageTitle: {
      get: function () {
        return "Caps Institutions List Details";
      },
    },
    currentRecord: {
      get: function () {
        return this.$store.getters["caps_institutions_list/currentRecord"];
      },
      set: function (value) {
        this.$store.commit("caps_institutions_list/setCurrentRecord", value);
      },
    },
  },
  meta() {
    return {
      title: this.pageTitle,
    };
  },
  methods: {
    ...mapActions("caps_institutions_list", ["fetchRecord", "deleteRecord"]),
    getActionMenuModel(data) {
      return [];
    },
  },
  watch: {
    $route(to, from) {
      //only fetch data when navigated to this page
      if (to.name == this.routeName) {
        //this.load();
      }
    },
  },
  async mounted() {},
  async created() {},
};
</script>
<style scoped></style>
<style scoped></style>
